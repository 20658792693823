import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppState, RootState } from '@/definitions/store'

const state: AppState = {
  isLoading: false
}

const getters: GetterTree<AppState, RootState> = {
  isLoading: (state): boolean => state.isLoading
}

const mutations: MutationTree<AppState> = {
  setIsLoading (state, isLoading: boolean) {
    state.isLoading = isLoading
  }
}

const actions: ActionTree<AppState, RootState> = {
  async setIsLoading ({ commit }, isLoading: boolean): Promise<void> {
    commit('setIsLoading', isLoading)
  }
}

export const app: Module<AppState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
