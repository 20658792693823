import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { SHA256 } from 'crypto-js'
import { User } from '@/definitions/user'
import { RootState, UserState } from '@/definitions/store'
import axiosInstance from '@/plugins/axios'
import router from '@/router'

const state: UserState = {
  currentUser: null
}

const getters: GetterTree<UserState, RootState> = {
  currentUser: (state): User | null => state.currentUser
}

const mutations: MutationTree<UserState> = {
  setCurrentUser (state, currentUser: User) {
    state.currentUser = currentUser
  }
}

const actions: ActionTree<UserState, RootState> = {
  async setCurrentUser ({ commit }, user: User): Promise<void> {
    commit('setCurrentUser', user)
  },

  async logout ({ commit }): Promise<void> {
    localStorage.removeItem('SCPPORTAL_ACCESS_TOKEN')
    commit('setCurrentUser', null)
    await router.push('/login')
  }
}

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
