import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { store } from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: { path: '/products' }
  },
  {
    path: '/products',
    name: 'ProductsView',
    component: () => import('../views/ProductsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/products/:productId',
    name: 'ProductEdit',
    component: () => import('../views/ProductEdit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/add-product',
    name: 'ProductAdd',
    component: () => import('../views/ProductAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'UsersView',
    component: () => import('../views/UsersView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:username',
    name: 'UserEdit',
    component: () => import('../views/UserEdit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/add-user',
    name: 'UserAdd',
    component: () => import('../views/UserAdd.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['user/currentUser']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
