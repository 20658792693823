import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { RootState } from '@/definitions/store'
import { app } from '@/store/modules/app'
import { user } from '@/store/modules/user'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    app,
    user
  },
  plugins: [
    createPersistedState({
      key: 'SCPPORTAL',
      reducer: (state : RootState) => ({
        user: state.user
      })
    })
  ],
  strict: true
})
